<template>
  <div id="sidebar">
    <div class="sidebar-container">
      <div class="profile">
        <div class="profile-image">
          <img src="../assets/images/profile.svg" alt="" />
        </div>
        <div class="name">
          {{
            user.user
              ? user.user.first_name + " " + user.user.last_name
              : "------"
          }}
        </div>
        <div class="phone">{{ user.user ? user.user.mobile : "------" }}</div>
      </div>
    </div>
    <div class="menu">
      <ul>
        <li
          @click.prevent="goDashboard()"
          :class="{ active: $route.path === '/dashboard' }"
        >
          <div class="item">
            <img src="../assets/images/dashboard-icon.svg" alt="" />
            <a href="#">داشبورد</a>
          </div>
        </li>
        <li @click.prevent="showSubAccountHandler">
          <div class="item" :class="showSubAccount ? 'rotate' : ''">
            <img src="../assets/images/profil-cion.svg" alt="" />
            <a href="#">حساب کاربری</a>
            <img
              class="arrow"
              src="../assets/images/panel-forward-icon.svg"
              alt=""
            />
          </div>
          <ul v-if="showSubAccount" class="dropdown">
            <div
              class="sub-item"
              @click.prevent="goAccountAuthentication"
              :class="{ active: $route.path === '/account-authentication' }"
            >
              <img
                class="sub-item-icon"
                src="../assets/images/profil-cion.svg"
                alt=""
              />
              <a class="" href="#">حساب کاربری اهراز هویت</a>
            </div>
            <div
              class="sub-item"
              @click.prevent="goChangePassword"
              :class="{ active: $route.path === '/change-password' }"
            >
              <img
                class="sub-item-icon"
                src="../assets/images/ri_lock-password-line.svg"
                alt=""
              />
              <a href="#">تغییر رمز عبور</a>
            </div>
            <div
              class="sub-item"
              @click.prevent="goIdentifyTwoFactors"
              :class="{ active: $route.path === '/identify-two-factors' }"
            >
              <img
                class="sub-item-icon"
                src="../assets/images/shild-icon.svg"
                alt=""
                style="width: 16px"
              />
              <a href="#">شناسایی رمز عبور</a>
            </div>
          </ul>
        </li>
        <li :class="{ active: $route.path === '/wallet' }">
          <div class="item" @click.prevent="goWallet">
            <img src="../assets/images/la_wallet.svg" alt="" />
            <a href="#">کیف پول</a>
          </div>
        </li>
        <li>
          <div
            class="item"
            @click.prevent="
              showSubFinance = true;
              goBankCard();
            "
            :class="showSubFinance ? 'rotate' : ''"
          >
            <img src="../assets/images/fa-regular_money-bill-alt.svg" alt="" />
            <a href="#">امور مالی</a>
            <img
              class="arrow"
              src="../assets/images/panel-forward-icon.svg"
              alt=""
            />
          </div>
          <ul v-if="showSubFinance" class="dropdown">
            <div
              class="sub-item"
              @click.prevent="goBankCard"
              :class="{ active: $route.path === '/bank-cards' }"
            >
              <img
                class="sub-item-icon"
                src="../assets/images/profil-cion.svg"
                alt=""
              />
              <a class="" href="#">کارت های بانکی</a>
            </div>
            <div
              class="sub-item"
              @click.prevent="goTransaction"
              :class="{ active: $route.path === '/transaction' }"
            >
              <img
                class="sub-item-icon"
                src="../assets/images/ri_lock-password-line.svg"
                alt=""
              />
              <a class="" href="#">تراکنش ها</a>
            </div>
            <div
              class="sub-item"
              @click.prevent="goEarnMony"
              :class="{ active: $route.path === '/earn-money' }"
            >
              <img
                class="sub-item-icon"
                src="../assets/images/shild-icon.svg"
                alt=""
                style="width: 16px"
              />
              <a href="#">کسب درآمد</a>
            </div>
          </ul>
        </li>
        <li>
          <div
            class="item"
            @click.prevent="
              showSubServices = !showSubServices;
              goDigitalCurrencyEquations();
            "
            :class="showSubServices ? 'rotate' : ''"
          >
            <img src="../assets/images/credit-card.svg" alt="" />
            <a href="#">خدمات</a>
            <img
              class="arrow"
              src="../assets/images/panel-forward-icon.svg"
              alt=""
            />
          </div>
          <ul v-if="showSubServices" class="dropdown">
            <div
              class="sub-item-services"
              @click.prevent="goDigitalCurrencyEquations"
            >
              <img
                class="sub-item-icon-services"
                src="../assets/images/ramz-arz.svg"
                alt=""
              />
              <a
                :class="{
                  active: $route.path === '/digital-currency-equations'
                }"
                href="#"
                >معاملات رمزارزها</a
              >
            </div>
            <div
              class="sub-item-services"
              :class="showSubVoucher ? 'rotate' : ''"
              @click.prevent="goVoucherServices"
            >
              <img
                class="sub-item-icon-services"
                src="../assets/images/vucher.svg"
                alt=""
              />
              <a href="#" @click.prevent="showSubVoucher = !showSubVoucher"
                >خدمات ووچر</a
              >
              <img
                class="arrow"
                src="../assets/images/panel-forward-icon.svg"
                alt=""
              />
            </div>
            <ul v-if="showSubVoucher" class="dropdown">
              <div class="sub-item-voucher" @click.prevent="goVoucherServices">
                <img
                  class="sub-item-icon-voucher"
                  src="../assets/images/perfect-money.svg"
                  alt=""
                />
                <a
                  :class="{
                    active: $route.path === '/voucher-services'
                  }"
                  href="#"
                  >ووچر پرفکت مانی</a
                >
              </div>
              <div class="sub-item-voucher" @click.prevent="goHotVoucher">
                <img
                  class="sub-item-icon-voucher"
                  src="../assets/images/hot-vucher.svg"
                  alt=""
                />
                <a
                  :class="{
                    active: $route.path === '/hot-voucher'
                  }"
                  href="#"
                  >هات وچرر</a
                >
              </div>
              <div class="sub-item-voucher" @click.prevent="goPSVoucher">
                <img
                  class="sub-item-icon-voucher"
                  src="../assets/images/PSVouchers.svg"
                  alt=""
                />
                <a
                  :class="{
                    active: $route.path === '/ps-voucher'
                  }"
                  href="#"
                  >پی اس ووچر</a
                >
              </div>
            </ul>
            <div class="sub-item" @click.prevent="goPerfectMoneyService">
              <img
                class="sub-item-icon-services"
                src="../assets/images/perfect-money.svg"
                alt=""
              />
              <a
                :class="{
                  active: $route.path === '/perfect-money-service'
                }"
                href="#"
                >خدمات پرفکت مانی</a
              >
            </div>
            <div class="sub-item-services" @click.prevent="goWebMoneyService">
              <img
                class="sub-item-icon-services"
                src="../assets/images/web-money.svg"
                alt=""
              />
              <a
                :class="{
                  active: $route.path === '/web-money-service'
                }"
                href="#"
                >خدمات وب مانی</a
              >
            </div>
            <div class="sub-item-services" @click.prevent="goPayPalService">
              <img
                class="sub-item-icon-services"
                src="../assets/images/pay-bal.svg"
                alt=""
              />
              <a
                :class="{
                  active: $route.path === '/paypal-service'
                }"
                href="#"
                >خدمات پی بال</a
              >
            </div>
            <div class="sub-item-services" @click.prevent="goSkrill">
              <img
                class="sub-item-icon-services"
                src="../assets/images/skrill-icon.svg"
                alt=""
              />
              <a
                :class="{
                  active: $route.path === '/skrill-service'
                }"
                href="#"
                >خدمات اسکریل</a
              >
            </div>
          </ul>
        </li>
        <li>
          <div
            class="item"
            @click.prevent="goOrders"
            :class="{
              active: $route.path === '/orders'
            }"
          >
            <img src="../assets/images/checklist.png" alt="" />
            <a href="#">سفارشات</a>
          </div>
        </li>
        <li>
          <div
            class="item"
            @click.prevent="goSupport"
            :class="{
              active: $route.path === '/support'
            }"
          >
            <img src="../assets/images/operator.svg" alt="" />
            <a href="#">پشتیبان</a>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "sidebar",
  data() {
    return {
      user: [],
      showSubAccount: false,
      showSubFinance: false,
      showSubServices: false,
      showSubVoucher: false,
      toggleSidebar: false
    };
  },
  mounted() {
    this.getUserInformation();

    if (this.$route.path === "/account-authentication") {
      this.showSubAccount = true;
    }
    if (this.$route.path === "/identify-two-factors") {
      this.showSubAccount = true;
    }
    if (this.$route.path === "/change-password") {
      this.showSubAccount = true;
    }

    if (
      this.$route.path === "/bank-cards" ||
      this.$route.path === "/transaction" ||
      this.$route.path === "/earn-money"
    ) {
      this.showSubFinance = true;
    }

    if (
      this.$route.path === "/digital-currency-equations" ||
      this.$route.path === "/perfect-money-service" ||
      this.$route.path === "/web-money-service" ||
      this.$route.path === "/paypal-service" ||
      this.$route.path === "/skrill-service"
    )
      this.showSubServices = true;

    if (
      this.$route.path === "/voucher-services" ||
      this.$route.path === "/hot-voucher" ||
      this.$route.path === "/ps-voucher"
    ) {
      this.showSubServices = true;
      this.showSubVoucher = true;
    }
  },
  methods: {
    toggleShow() {
      const sideBar = document.querySelector("#sidebar");
      const sideBarNavBar = document.querySelector(".sidebar-menu-box");
      sideBar.classList.toggle("show");
      sideBarNavBar.classList.toggle("show");
      if (sideBarNavBar.classList.contains("active")) {
        sideBarNavBar.classList.remove("active");
        sideBarNavBar.classList.add("close");
        setTimeout(() => {
          sideBarNavBar.classList.remove("close");
        }, 1000);
      }
    },
    goDashboard() {
      if (this.$router.currentRoute.path === "/dashboard") return false;
      this.showSubAccount = false;
      this.showSubFinance = false;
      this.showSubServices = false;
      this.showSubVoucher = false;
      this.$router.push("/dashboard");
      this.toggleShow();
    },
    goAccountAuthentication() {
      this.showSubFinance = false;
      this.showSubServices = false;
      this.showSubVoucher = false;
      // this.toggleSidebar = false;
      if (this.$router.currentRoute.path === "/account-authentication")
        return false;
      this.$router.push("/account-authentication");
      this.toggleShow();
    },
    goChangePassword() {
      if (this.$route.path === "/change-password") return false;
      this.$router.push("/change-password");
      this.toggleShow();
    },
    goIdentifyTwoFactors() {
      if (this.$route.path === "/identify-two-factors") return false;
      this.$router.push("/identify-two-factors");
      this.toggleShow();
    },
    goWallet() {
      if (this.$route.path === "/wallet") return false;
      this.showSubAccount = false;
      this.showSubVoucher = false;
      this.showSubServices = false;
      this.showSubFinance = false;
      this.$router.push("/wallet");
      this.toggleShow();
    },
    goBankCard() {
      if (this.$route.path === "/bank-cards") return false;
      this.showSubAccount = false;
      this.showSubServices = false;
      this.showSubVoucher = false;
      this.$router.push("/bank-cards");
      this.toggleShow();
    },
    goTransaction() {
      if (this.$route.path === "/transaction") return false;
      this.$router.push("/transaction");
      this.toggleShow();
    },
    goEarnMony() {
      if (this.$route.path === "/earn-money") return false;
      this.$router.push("/earn-money");
      this.toggleShow();
    },
    goDigitalCurrencyEquations() {
      if (this.$route.path === "/digital-currency-equations") return false;
      this.showSubFinance = false;
      this.showSubAccount = false;
      this.showSubVoucher = false;
      this.$router.push("/digital-currency-equations");
      this.toggleShow();
    },
    goVoucherServices() {
      if (this.$route.path === "/voucher-services") return false;
      this.$router.push("/voucher-services");
      this.toggleShow();
    },
    goHotVoucher() {
      if (this.$route.path === "/hot-voucher") return false;
      this.$router.push("/hot-voucher");
      this.toggleShow();
    },
    goPSVoucher() {
      if (this.$route.path === "/ps-voucher") return false;
      this.$router.push("/ps-voucher");
      this.toggleShow();
    },
    goPerfectMoneyService() {
      if (this.$route.path === "/perfect-money-service") return false;
      this.showSubVoucher = false;
      this.$router.push("/perfect-money-service");
      this.toggleShow();
    },
    goWebMoneyService() {
      if (this.$route.path === "/web-money-service") return false;
      this.showSubVoucher = false;
      this.$router.push("/web-money-service");
      this.toggleShow();
    },
    goPayPalService() {
      if (this.$route.path === "/paypal-service") return false;
      this.showSubVoucher = false;
      this.$router.push("/paypal-service");
      this.toggleShow();
    },
    goSkrill() {
      if (this.$route.path === "/skrill-service") return false;
      this.showSubVoucher = false;
      this.$router.push("/skrill-service");
      this.toggleShow();
    },
    goOrders() {
      if (this.$route.path === "/orders") return false;
      this.showSubFinance = false;
      this.showSubAccount = false;
      this.showSubVoucher = false;
      this.showSubServices = false;
      this.$router.push("/orders");
      this.toggleShow();
    },
    goSupport() {
      if (this.$route.path === "/support") return false;
      this.showSubAccount = false;
      this.showSubServices = false;
      this.showSubVoucher = false;
      if (this.$route.path === "/bank-cards") return false;
      if (this.$route.path === "/transaction") return false;
      if (this.$route.path === "/earn-money") return false;
      this.$router.push("/support");
      this.toggleShow();
    },
    showSubAccountHandler() {
      this.showSubFinance = false;
      this.showSubServices = false;
      this.showSubVoucher = false;
      if (this.$route.path === "/account-authentication") return false;
      if (this.$route.path === "/change-password") return false;
      if (this.$route.path === "/identify-two-factors") return false;
      this.showSubAccount = !this.showSubAccount;
      this.$router.push("/account-authentication");
    },
    async getUserInformation() {
      try {
        let response = await axios("/panel/dashboard");
        this.user = response.data;
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>
<style scoped>
#sidebar {
  position: fixed;
  min-width: 250px;
  max-width: 250px;
  min-height: 100vh;
  max-height: 100vh;
  background: #080f66;
  top: 50px;
  right: 0;
  overflow: auto;
  z-index: 10000;
  transition: transform 0.5s linear;
}

.menu {
  margin-bottom: 100px;
}

.sidebar-container {
  position: sticky;
  top: 0;
  background-color: #080f66;
  z-index: 9999;
}

#dashbord-show {
  color: #fff;
  font-size: 34px;
  position: absolute;
  right: 80%;
  cursor: pointer;
}

.profile {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-height: 230px;
  min-height: 230px;
  margin: auto;
  border-bottom: 1px solid rgba(255, 255, 255, 0.04);
}

.name {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  position: relative;
  align-items: center;
  color: #fff;
}

.phone {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  position: relative;
  color: #fff;
}

a {
  color: #fff;
  margin-right: 10px;
}

.arrow {
  position: absolute;
  left: 12px;
  transition: 0.3s all ease;
}

.rotate .arrow {
  transform: rotate(-90deg);
}

.dropdown {
  background-color: rgba(18, 18, 18, 0.4);
}

.item {
  border-bottom: 1px solid rgba(255, 255, 255, 0.04);
  padding: 10px 30px 10px 0;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.item a {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
}

.sub-item {
  font-size: 14px;
  padding: 10px 50px 10px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.04);
  display: flex;
  align-items: center;
}

.sub-item a {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
}

.sub-item-icon {
  width: 20px;
}

.sub-item-services {
  font-size: 16px;
  padding: 10px 50px 10px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.04);
  display: flex;
  align-items: center;
}

.sub-item-icon-services {
  width: 25px;
}

.sub-item-voucher {
  font-size: 16px;
  padding: 10px 70px 10px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.04);
  display: flex;
  align-items: center;
}

.sub-item-icon-voucher a {
  font-size: 14px;
}

.sub-item-icon-voucher {
  width: 25px;
}

.active {
  color: #fb9224;
  filter: invert(55%) sepia(96%) saturate(514%) hue-rotate(343deg)
    brightness(102%) contrast(97%);
}

.show {
  transform: translateX(0) !important;
}

@media (max-width: 992px) {
  #sidebar {
    transform: translate(200%, 0);
  }
}
</style>
