<template>
  <div id="panel-container">
    <Sidebar />
    <!-- content -->
    <router-view />
    <!-- footer -->
    <!--    <panel-footer />-->
  </div>
</template>
<script>
import Sidebar from "../components/Sidebar.vue";
// import PanelFooter from "@/components/PanelFooter";

export default {
  components: {
    Sidebar
  },
  data() {
    return {};
  }
};
</script>
<style scoped></style>
